


















import { Vue, Component } from 'vue-property-decorator';
import VueRouter from 'vue-router';
import { clientStores } from '@/store/poultry/client/';
import { ClientResponse } from '@/models/poultry/client.model';
import { getRowNumber } from '@/utils/pagination';

@Component({
  components: {}
})
export default class ClientTableBody extends Vue {
  $router!: VueRouter;
  store = clientStores.list;

  get clients(): ClientResponse[] {
    return this.store.sortedClients;
  }

  getRowNumber() {
    return getRowNumber(this.store.pagination);
  }

  handleClientClicked(id: string) {
    this.$router.push({
      name: 'client.details',
      params: {
        id
      }
    })
  }
}
