
















import { Vue, Component } from 'vue-property-decorator';
import Page from '@/components/poultry/layout/Page.vue';
import ClientTableHeader from './ClientTableHeader.vue';
import ClientTableBody from './ClientTableBody.vue';
import { clientStores } from '@/store/poultry/client';
import Pagination from '@/components/poultry/Pagination.vue';
import { PaginationModel } from '@/models/poultry/pagination.model';

@Component({
  components: {
    Page,
    ClientTableHeader,
    Pagination,
    ClientTableBody,
  },
})
export default class ClientTable extends Vue {
  store = clientStores.list;

  created() {}

  handlePageCountChanged(params: PaginationModel) {
    this.store.updatePagination(params);
    this.store.fetchAll(params);
  }
}
