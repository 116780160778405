



















































import { Vue, Component } from 'vue-property-decorator';
import { clientStores } from "@/store/poultry/client";
import SortIndicator from '@/components/poultry/SortIndicator.vue';

@Component({
  components: {
    SortIndicator
  }
})
export default class ClientTableHeader extends Vue {
  store = clientStores.list;

  sort(sortType: any) {
    this.store.setSortType(sortType);
  }

  get currentSort() {
    return this.store.currentSort;
  }

  get currentSortOrder() {
    return this.store.currentSortOrder.reverse;
  }
}
