






























































































import { Component, Vue, Watch, Emit } from 'vue-property-decorator';
import Modal from '@/components/poultry/Modal.vue';
import Dropdown from '@/components/poultry/Dropdown.vue';
import { clientStores } from '@/store/poultry/client';
import { dropdownStores } from '@/store/poultry/dropdowns';
import { Country, Region, State, City } from '@/models/poultry/country.model';
import { constructValidationError } from '@/utils/errors';
import { ToastProgrammatic as Toast } from 'buefy';
import { axios } from '@/utils/axios';
import { Endpoints } from '@/store/poultry/endpoints';

@Component({
  components: {
    Modal,
    Dropdown,
  },
})
export default class CreateClientForm extends Vue {
  store = clientStores.create;
  countryStore = dropdownStores.country;
  errors: { [key: string]: string } = {};
  countries: Country[] = [];
  states: State[] = [];
  regions: Region[] = [];
  cities: City[] = [];
  client = {
    name: '',
    addressLine1: '',
    addressLine2: '',
    postcode: '',
    cityId: '',
    stateId: '',
    regionId: '',
    countryId: '',
    phoneNumber: '',
    city: '',
    country: '',
    state: ''
  };

  @Watch('errors')
  get validationErrors() {
    const error = constructValidationError(this.store.error);
    return error;
  }

  async created() {
    await this.fetchCountriesDropdown();
  }

  async fetchCountriesDropdown() {
    try {
      const { data } = await axios.get(Endpoints.Countries);
      this.countries = data;
    } catch (error) {
      console.error(error);
    }
  }

  async fetchRegionsDropdown() {
    try {
      const params: {[key: string] : string} = {};

      if (this.client.countryId) {
        params['country'] = this.client.countryId;
      }

      const { data } = await axios.get(Endpoints.Regions, 
        {params},
      );

      this.regions = data;
    } catch (error) {
      console.error(error);
    }
  }
  
  async fetchStatesDropdown() {
    try {
      const params: {[key: string] : string} = {};

      if (this.client.regionId) {
        params['region'] = this.client.regionId;
      }

      const { data } = await axios.get(Endpoints.States, 
        {params},
      );

      this.states = data;
    } catch (error) {
      console.error(error);
    }
  }

  async fetchCitiesDropdown() {
    try {
      const params: {[key: string] : string} = {}

      if (this.client.stateId) {
        params['state'] = this.client.stateId;
      }

      const { data } = await axios.get(Endpoints.Cities, 
        {params},
      );

      this.cities = data;
    } catch (error) {
      console.error(error);
    }
  }

  async handleFormSubmitted() {
    try {
      if (this.client.name && this.client.countryId && this.client.cityId && this.client.stateId) {
        const species = (this.$router.currentRoute.fullPath.split('/'))[1];

        this.store.updateState(this.client);
        this.store.setSpecies(species)
        await this.store.create();

        if (this.store.isCreated) {
          Toast.open({
            type: 'is-success',
            message: 'Client successfully created',
          });
          this.closeCreateClientModal();
          await clientStores.list.fetchAll();
        }
      } else {
        Toast.open({
          type: 'is-danger',
          message: 'Required fields cannot be empty',
        });
      }
    } catch (error) {
      Toast.open({
        type: 'is-danger',
        message: 'Client cannot be created',
      });
    }
  }

  handleLocationChanged(type: string) {
    if (type === 'city') {
      return;
    } else if (type === 'country') {
      if (!this.client.countryId) {
        this.client.regionId = '';
        this.client.stateId = '';
        this.client.cityId = '';
        this.regions = [];
        this.states = [];
        this.cities = [];
      } else {
        this.fetchRegionsDropdown();
      }
    } else if (type === 'region') {
      if (!this.client.regionId) {
        this.client.stateId = '';
        this.client.cityId = '';
        this.states = [];
        this.cities = [];
      } else {
        this.fetchStatesDropdown();
      }
    } else if (type === 'state') {
      if (!this.client.stateId) {
        this.client.cityId = '';
        this.cities = [];
      } else {
        this.fetchCitiesDropdown();
      }
    }
  }

  @Emit('onClose')
  closeCreateClientModal() {}
}
