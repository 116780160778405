


































import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class ClientPageHeader extends Vue {
  @Prop({
    type: Number,
  })
  private count!: number;

  keywords: string = '';

  get totalClientsText() {
    if (this.count > 1) {
      return 'Total Clients';
    }

    return 'Total Client';
  }

  @Emit('onSearch')
  searchByKeywords() {
    return this.keywords;
  }

  @Emit('onCreateNewClient')
  createNewClient() {}
}
