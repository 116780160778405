import { ErrorResponse } from '@/models/poultry/response';

export const codesToErrors: {[key: string] : string} = {
  'is_defined': 'This field is required',
  'is_not_empty': 'This field cannot be empty',
  'client_name_already_in_use': 'Client name is already in use',
  'validation_error' : 'Incorrect email or password',
  'incorrect_username_or_password' : 'Incorrect email or password',
  'user_not_found': 'User not found'
};

const DEFAULT_ERROR_MESSAGE = 'Something went wrong';

export function getMessageFromError(error: ErrorResponse | null): string {
  if (!error) {
    return DEFAULT_ERROR_MESSAGE;
  }

  return codesToErrors[error.code] || DEFAULT_ERROR_MESSAGE;
}

export function constructValidationError(
  error: ErrorResponse | null,
): { [key: string]: string } | object {
  if (!error) {
    return {};
  }

  const errors = {} as any;

  error.errors.forEach(error => {
    errors[error.field] = error.code;
  });

  return errors;
}
