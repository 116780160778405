





























import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import Page from '@/components/poultry/layout/Page.vue';
import ClientTable from './ClientTable/ClientTable.vue';
import Modal from '@/components/poultry/Modal.vue';
import CreateClientForm from './CreateClientForm.vue';
import { clientStores } from '@/store/poultry/client';
import ClientPageHeader from './ClientPageHeader.vue';

@Component({
  components: {
    Page,
    ClientTable,
    Modal,
    CreateClientForm,
    ClientPageHeader,
  },
})
export default class Client extends Vue {
  store = clientStores.list;
  showCreateClientModal = false;
  created() {
    const species = (this.$router.currentRoute.fullPath.split('/'))[1];

    document.title = 'Elanco | Client';
    this.store.setSpecies(species);
    this.store.fetchAll();
  }
  async searchByKeywords(inputKeywords: any) {
    if (!inputKeywords.trim()) {
      await this.store.fetchAll(this.store.defaultPagination);
      return;
    }
    this.store.searchByKeywords({
      keywords: inputKeywords,
    });
  }
}
